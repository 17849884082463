import { types } from 'actions/admin';

const usersReducer = (state = [], action) => {
    switch (action.type) {
        case types.GET_USERS_SUCCESS:
            return [ ...action.payload ];
        default: return state;
    }
}

export default usersReducer;