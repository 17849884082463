import React, { useState } from 'react';
import { dropletLabelsByType } from 'lib/mappings';
import { Popover, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const dropletTypes = Array.from(dropletLabelsByType.keys());

const AddDropletButton = ({ droplets = [], onDropletSelect }) => {

    const [popoverVisible, setPopoverVisible] = useState(false);

    const handleDropletButtonClick = (dropletType) => {
        setPopoverVisible(false);
        onDropletSelect(dropletType);
    }

    const dropletTypesInUse = droplets.map(droplet => droplet.type);

    return (
        <Popover
            placement="right"
            trigger="click"
            visible={popoverVisible}
            onVisibleChange={setPopoverVisible}
            content={
                <div>
                    {dropletTypes.map((dropletType, idx) => {
                        return (
                            <div key={idx}>
                                <Button
                                    disabled={dropletTypesInUse.includes(dropletType)}
                                    className='droplet-option'
                                    type="link"
                                    onClick={handleDropletButtonClick.bind(this, dropletType)}
                                >
                                    {dropletLabelsByType.get(dropletType)}
                                </Button>
                            </div>
                        )
                    })}
                </div>
            }
        >
            <Button size="small" style={{width: '40px', marginLeft:'40px', marginTop: 10}}>
                <PlusOutlined />
            </Button>
        </Popover>
    );
}

export default AddDropletButton;