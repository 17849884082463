import React from 'react';
import { Row, Col } from 'antd';
import Users from './Users';

const Admin = () => {
    return (
        <Row justify="center">
            <Col flex="1200px">
                <Users />
            </Col>
        </Row>
    )
}

export default Admin;