import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import dayjs from 'dayjs';
import { getUsers, resetUserPassword } from '../../../actions/admin';
import { Table, Button, Popconfirm, message } from 'antd';
import { CopyOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { copyToClipboard } from '../../../lib/helpers';

const { Column } = Table;

const Users = ({ loading, users, getUsers, resetUserPassword }) => {

    useEffect(() => {
        getUsers();
    }, [getUsers]);


    const handleUserIdClick = (id) => {
        copyToClipboard(id);
        message.info('Copied to clipboard.');
    }

    const handleConfirmResetPassword = async (userId) => {
        const newPassword = await resetUserPassword(userId);
        if (newPassword) {
            copyToClipboard(newPassword);
            message.success('Password has been reset and copied to clipboard.');
        }
    }

    return (
        <>
            <h2>Users {users.length ? `(${users.length})` : null}</h2>
            <Table dataSource={users.map(user => ({ ...user, key: user._id }))} pagination={false} loading={loading.GET_USERS}>
                <Column title="Email" dataIndex="email" key="email" />
                <Column title="Role" dataIndex="role" key="role" render={role => _.capitalize(_.startCase(role))} />
                <Column title="Member Since" dataIndex="createdAt" key="createdAt" render={createdAt => (
                    dayjs(createdAt).format('MMM DD, YYYY')
                )}/>
                <Column title="ID" dataIndex="_id" key="id" render={id => (
                    <>
                        {id}
                        <Button type="link" icon={<CopyOutlined />} onClick={handleUserIdClick.bind(this, id)} />
                    </>
                )} />
                <Column title="Reset Password" key="reset-password" render={user => (
                    <Popconfirm
                        title="Are you sure？"
                        icon={<QuestionCircleOutlined style={{ color: 'red' }}/>}
                        onConfirm={handleConfirmResetPassword.bind(this, user._id)}
                    >
                        <Button type="link">Reset Password</Button>
                    </Popconfirm>
                )} />
            </Table>
        </>
    )
}

const mapStateToProps = ({ loading, admin: { users } }) => ({ loading, users });
export default connect(mapStateToProps, { getUsers, resetUserPassword })(Users);