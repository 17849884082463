import React from 'react';
import { Form } from 'antd';
import { FastField } from 'formik';
import FileUploader from 'components/Dashboard/FileUploader';

const AudioSample = ({ experienceId, dataPath }) => (
    <>
        <Form.Item label="Audio File (.mp3 or .m4a)">
            <FastField name={`${dataPath}.url`}>
                {({ field: { value }, form: { setFieldValue }}) => (
                    <FileUploader
                        experienceId={experienceId}
                        onUploadSuccess={url => setFieldValue(`${dataPath}.url`, url)}
                        onRemoveFile={() => setFieldValue(`${dataPath}.url`, '')}
                        value={value}
                        accept=".mp3,.m4a"
                        maxFileSize={3}
                    />
                )}
            </FastField>
        </Form.Item>
    </>
);

export default AudioSample;