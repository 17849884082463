import React from 'react';
import styled from 'styled-components';

const ColumnContainer = styled.div`
    margin: 0 30px;
    display: flex;
    flex-direction: column;
    flex-grow: ${({ flexGrow }) => flexGrow};
    min-width: ${({ minWidth }) => minWidth};
    max-width ${({ maxWidth }) => maxWidth};
`

const ColumnSection = styled.div`
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    min-height: 0;
`

const ColumnScrollableContent = styled.div`
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 0;

    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        background-color: rgb(58 58 58 / 50%);
        -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }
`

export const Container = styled.div`
    display:flex;
    justify-content: center;
    height: 80vh;
`

export const Column = ({ children, minWidth = '0', maxWidth = '100%', flexGrow = '0', footerContent = null, style = null }) => (
    <ColumnContainer minWidth={minWidth} maxWidth={maxWidth} flexGrow={flexGrow} style={style}>
        <ColumnSection>
            <ColumnScrollableContent>
                {children}
            </ColumnScrollableContent>
        </ColumnSection>
        {footerContent ? <ColumnSection>{footerContent}</ColumnSection> : null}
    </ColumnContainer>
)