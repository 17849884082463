import apiClient from 'lib/apiClient';

class AuthService {
    async logIn(credentials) {
        try {
            const { data: { token, user } } = await apiClient({
                method: 'post',
                url: '/users/authenticate',
                data: credentials
            });
            return (apiClient.defaults.headers.common['token'] = setToken(token)) && user;
        } catch (err) {
            throw err;
        }
    }

    async signUp(fields) {
        try {
            const { data: { token, user } } = await apiClient({
                method: 'post',
                url: '/users',
                data: fields
            });
            return (apiClient.defaults.headers.common['token'] = setToken(token)) && user;
        } catch (err) {
            throw err;
        }
    }

    async getCurrentUser() {
        try {
            const { data: currentUser } = await apiClient({
                method: 'get',
                url: '/users/me'
            });
            return currentUser;
        } catch (err) {
            delete apiClient.defaults.headers.common['token'];
            clearToken();
            throw err;
        }
    }

    async updateAccount(fields) {
        try {
            const { data: { token, user: updatedUser } } = await apiClient({
                method: 'put',
                url: '/users/me',
                data: fields
            });
            return (apiClient.defaults.headers.common['token'] = setToken(token)) && updatedUser;
        } catch (err) {
            throw err;
        }
    }

    logOut() {
        clearToken();
    }
};

export function getToken() {
    return localStorage.getItem('token');
};

export function setToken(token) {
    localStorage.setItem('token', token);
    apiClient.defaults.headers.common['token'] = token;
    return token;
};

export function clearToken() {
    localStorage.removeItem('token');
    delete apiClient.defaults.headers.common['token'];
    return null;
};

// TODO: move this to app initialization
if (getToken()) setToken(getToken());

export default AuthService;