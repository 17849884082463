import React from 'react';
import { Link } from 'react-router-dom';
import { Typography, Button } from 'antd';
import icon404 from './404-error.svg';
import './NotFound.css';

const { Title, Paragraph } = Typography;

const NotFound = ({ history }) => {
    return (
        <div className="not-found">
            <div>
                <img className="icon-404" src={icon404} alt="404 Not Found" />
                <div className="content">
                    <Title level={3}>Not Found!</Title>
                    <Paragraph>Sorry, the page you requested does not exist.</Paragraph>
                </div>
                <Link to="/">
                    <Button type="primary">Take Me Home!</Button>
                </Link>
            </div>
        </div>
    );
}

export default NotFound;