import React from 'react';
import { Form, Input } from 'antd';
import { FastField } from 'formik';

const AugmentedReality = () => (
    <>
        <Form.Item label="AR File URL">
            <FastField name="data.url" as={Input} size="large" placeholder="AR File URL" />
        </Form.Item>
    </>
);

export default AugmentedReality;