import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

const Home = ({ currentUser }) => (
    <Fragment>
        {currentUser ? (
            <Redirect to="/dashboard" />
        ) : (
            <Redirect to="/login" />
        )}
    </Fragment>
)

const mapStateToProps = ({
    auth: { currentUser },
}) => ({ currentUser });
export default connect(mapStateToProps)(Home);