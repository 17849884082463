import React, { useState } from 'react';
import HubspotService from '../../../services/hubspot';
import { Button, Modal, Typography, Form, Input, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import './InviteFriend.css';

const { Title } = Typography;
const hubspot = new HubspotService();

const InviteFriend = () => {
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();

    const showModal = () => {
        setModalVisible(true);
    }

    const hideModal = () => {
        form.resetFields();
        setModalVisible(false);
    }

    const handleFormSubmit = async fields => {
        try {
            await hubspot.submitInviteFriendForm(fields);
            message.success("Your invitation is on the way! Thanks for helping us build the splashmob community. Let's create something awesome!", 10);
            hideModal();
        } catch (err) {
            message.error("There was a problem submitting the invitation, please try again later.");
        }
    }

    return (
        <>
            <Button
                className="invite-friend-button"
                type="primary"
                shape="round"
                size="large"
                icon={<MailOutlined />}
                onClick={showModal}
            >
                Invite A Friend
            </Button>
            <Modal
                centered
                visible={modalVisible}
                onCancel={hideModal}
                footer={null}
            >
                <>
                    <Title id="invite-friend-modal-title" level={1}>Invite your friends and colleagues to join splashmob's early access community!</Title>
                    <Form
                        form={form}
                        onFinish={handleFormSubmit}
                        layout="vertical"
                    >
                        <Form.Item
                            name="email"
                            label="Friend's Email"
                            rules={[{ required: true, message: 'Email is required!' }]}
                        >
                            <Input type="email" placeholder="Email" size="large" />
                        </Form.Item>
                        <Form.Item
                            name="firstname"
                            label="Friend's First Name"
                            rules={[{ required: true, message: 'First Name is required!' }]}
                        >
                            <Input type="text" placeholder="First Name" size="large" />
                        </Form.Item>
                        <Form.Item
                            name="lastname"
                            label="Friend's Last Name"
                            rules={[{ required: true, message: 'Last Name is required!' }]}
                        >
                            <Input type="text" placeholder="Last Name" size="large" />
                        </Form.Item>
                        <Form.Item
                            name="message"
                            label="Message (optional)"
                        >
                            <Input.TextArea placeholder="Message" size="large" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Send
                            </Button>
                        </Form.Item>
                    </Form>
                </>
            </Modal>
        </>
    )
}

export default InviteFriend;