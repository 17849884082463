export function arrayMove(arr, from, to){
    arr.splice(to, 0, arr.splice(from, 1)[0]);
    return arr;
};

export function fileIsImage(file) {
    return file && file.type.split('/')[0] === 'image';
}

export function copyToClipboard(str) {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style = { position: 'absolute', left: '-9999px' };
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}