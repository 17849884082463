import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { dropletLabelsByType, dropletDescriptionsByType } from 'lib/mappings';
import { Typography, Popover, Tabs, Popconfirm, Divider } from 'antd';
import { InfoCircleOutlined, DeleteOutlined, SettingOutlined, FontSizeOutlined, LinkOutlined, FileImageOutlined, VideoCameraOutlined, SoundOutlined, MenuOutlined, FormOutlined, CodeOutlined } from '@ant-design/icons';
import { useFormikContext, Field } from 'formik';
import Fields from './DropletFormFields';
import {Form as FormD} from 'antd';
import SliderField from 'components/Dashboard/SliderField';
import ColorPickerField from 'components/Dashboard/ColorPickerField';
import FileUploader from 'components/Dashboard/FileUploader';
import './DropletsPanel.css';

const { Title } = Typography;
const { TabPane } = Tabs;

const findIcon = (label) => {
    switch(label) {
        case 'Text':
            return <span><FontSizeOutlined style={{fontSize: '22px'}} /></span>
        case 'Link':
            return <span> <LinkOutlined style={{fontSize: '22px'}} /></span>
        case 'Image':
            return <span> <FileImageOutlined style={{fontSize: '22px'}} /></span>
        case 'Video':
            return <span> <VideoCameraOutlined style={{fontSize: '22px'}} /></span>
        case 'Audio':
            return <span> <SoundOutlined style={{fontSize: '22px'}} /></span>
        case 'Poll':
            return <span> <MenuOutlined style={{fontSize: '22px'}} /></span>
        case 'Form':
            return <span> <FormOutlined style={{fontSize: '22px'}} /></span>
        case 'HTML':
            return <span> <CodeOutlined style={{fontSize: '22px'}} /></span>
        default:
            return <span><SettingOutlined style={{fontSize: '22px'}} /></span>
    }
    
}

const DropletsTabPane = ({
    experience,
    experienceId,
    currentSlideIndex,
    currentDropletIndex,
    onSelectDroplet,
    onDeleteDroplet
}) => {

    const handleDropletSelect = (dropletIndex) => {
        if (dropletIndex !== undefined) {
            onSelectDroplet(parseInt(dropletIndex));
        }
    }

    const handleDropletDeleteClick = (event, dropletIndex) => {
        event.stopPropagation();
        onDeleteDroplet(dropletIndex);
    }

    const formikProps = useFormikContext();
    const { values, errors, touched } = formikProps;
    const { droplets } = values.slides[currentSlideIndex]
    const slidePath = `slides[${currentSlideIndex}]`

    return (
        <>
            <Tabs
                tabPosition={'left'}
                // activeKey={currentDropletIndex.toString()}
                onChange={handleDropletSelect}
                defaultActiveKey="1"
            >
                    <TabPane
                        key={1}
                        tab={findIcon()}
                        style={{width:'300px'}}>
                        <Title level={5}>
                            Slide Settings
                            <Popover
                                className="droplet-description"
                                title="Settings"
                                content="These are the global settings for this slide. Choose duration, background image or color"
                            >
                                <InfoCircleOutlined
                                    twoToneColor="#ff0000"
                                    style={{ fontSize: '18px', marginLeft: '5px' }}
                                />
                            </Popover>
                        </Title>
                        <Divider/>
                        <FormD.Item label={<>
                            Duration
                            </>}>
                            <SliderField
                                name={`${slidePath}.autoplay.duration`}
                                min={1000}
                                max={60000}
                                step={1000}
                                tipFormatter={value => `${value / 1000} sec.`}
                                defaultValue={10000}
                            />
                        </FormD.Item>
                        <FormD.Item label="Background Color">
                            <ColorPickerField name={`${slidePath}.background.color.value`} />
                        </FormD.Item>
                        <FormD.Item label="Background Image">
                            <Field name={`${slidePath}.background.imageUrl`}>
                                {({ field: { value }, form: { setFieldValue } }) => (
                                    <FileUploader
                                        experienceId={experience._id}
                                        onUploadSuccess={url => setFieldValue(`${slidePath}.background.imageUrl`, url)}
                                        onRemoveFile={() => setFieldValue(`${slidePath}.background.imageUrl`, '')}
                                        value={value}
                                        showImageCropTool={true}
                                        accept="image/*"
                                    />
                                )}
                            </Field>
                        </FormD.Item>
                    </TabPane>
                {droplets.map((droplet, dropletIndex) => {
                    const dropletLabel = dropletLabelsByType.get(droplet.type);
                    const dropletDescription = dropletDescriptionsByType.get(droplet.type);

                    // build path from droplets array to this droplet's data property
                    // to use in the form fields' names, as well as getting touched / error status
                    // for each field
                    const dropletDataPath = `slides[${currentSlideIndex}].droplets[${dropletIndex}].data`;
                    const dropletTouchedFields = _.get(touched, dropletDataPath)
                    const dropletErrors = _.get(errors, dropletDataPath);
                    const formProps = { dataPath: dropletDataPath, touched: dropletTouchedFields, errors: dropletErrors };
                    const shouldIndicateError = dropletErrors && dropletTouchedFields && Object.keys(dropletErrors).find(key => dropletTouchedFields[key] === true);

                    return (
                        <TabPane
                            key={dropletIndex +2}
                            tab={findIcon(dropletLabel)}
                            style={{width:'300px'}}
                        >
                            <div className="ant-form ant-form-vertical">
                                <Title type={shouldIndicateError ? 'danger' : null} level={5}>{dropletLabel} {dropletDescription ? (
                                    <Popover
                                        className="droplet-description"
                                        title={dropletLabel}
                                        content={dropletDescription}
                                    >
                                        <InfoCircleOutlined
                                            twoToneColor="#ff0000"
                                            style={{ fontSize: '18px' }}
                                        />
                                    </Popover>) : null}
                                    <Popconfirm
                                        title="Delete this droplet?"
                                        onConfirm={evt => handleDropletDeleteClick(evt, dropletIndex)}
                                        onCancel={evt => evt.stopPropagation()}
                                        okText="Yes"
                                        cancelText="No">
                                        <DeleteOutlined
                                            key="delete"
                                            twoToneColor="#ff0000"
                                            style={{ fontSize: '18px', padding:'10px', float:'right' }}
                                            onClick={evt => evt.stopPropagation()}
                                        />
                                    </Popconfirm>
                                </Title>
                                
                                <Divider />
                                {{
                                    'PLAY_SAMPLE': <Fields.AudioSample experienceId={experienceId} {...formProps} />,
                                    'SHOW_MAIN_IMAGE': <Fields.Image experienceId={experienceId} {...formProps} />,
                                    'SHOW_VIDEO': <Fields.Video experienceId={experienceId} {...formProps} />,
                                    'SHOW_LINK': <Fields.Link experienceId={experienceId} {...formProps} />,
                                    'SHOW_POLL': <Fields.Poll {...formProps} />,
                                    'SHOW_CUSTOM_HTML': <Fields.CustomHTML {...formProps} />,
                                    'SHOW_TEXT_INPUT': <Fields.TextInput {...formProps} />,
                                    'SHOW_TEXT': <Fields.Text {...formProps} />,
                                }[droplet.type]}
                            </div>
                        </TabPane>
                    )
                })}
            </Tabs>
        </>
    )
}

const mapStateToProps = ({
    editor: {
        currentSlideIndex,
        currentDropletIndex,
    }
}) => ({ currentSlideIndex, currentDropletIndex });

export default connect(mapStateToProps)(DropletsTabPane);