import {useState} from 'react';
import { Field, useFormikContext } from 'formik';
import { Drawer, Typography, Form, Input, Divider, Select, Tooltip, Popover } from 'antd';
import ColorPickerField from 'components/Dashboard/ColorPickerField';
import SliderField from 'components/Dashboard/SliderField';
import FileUploader from 'components/Dashboard/FileUploader';
import styled from 'styled-components';
import NameForm from '../NameForm';

const { Title } = Typography;
const { Option } = Select;

const SlideSettings = ({ experience, visible, onClose }) => {
    const { values } = useFormikContext();

    const setFontValue = (fontOption) => {
        experience.font = values.font = fontOption;
    }

    const FontStyled = styled.div`
        font-family: ${({ value }) => value};
    `

    return (
        <Drawer
            title="Story Settings"
            placement="left"
            closable={true}
            onClose={onClose}
            visible={visible}
            width={350}>
            <div className="ant-form ant-form-vertical">
                <Form.Item label={<>
                        Title
                    </>}>
                        <Tooltip title="Only you will see this" mouseEnterDelay={0.2}>
                        <NameForm />
                        </Tooltip>
                </Form.Item>
                <Divider />
                <Title level={4}>Story Mode Options</Title>
                <Popover
                    placement="top"
                    className="droplet-description"
                    title="Story Mode"
                    content="Your audience can view the content of your story by tapping right and left.">
                         <Form.Item>
                            <Field name={`storyMode`} type="checkbox" size="large" /> Enable Story Mode
                        </Form.Item>
                </Popover>
                <Popover
                    placement="top"
                    className="droplet-description"
                    title="Autoplay"
                    content="Each time a slide is finished, the next one will play automatically">
                        <Form.Item >
                            <Field name={`storyAutoplay`} type="checkbox" size="large"/> Enable Autoplay
                        </Form.Item>
                </Popover>
                {/* <Popover
                    placement="top"
                    className="droplet-description"
                    title="Loop"
                    content="When the last slide has played, go back to beginning">
                        <Form.Item >
                            <Field name={`${slidePath}.background.color.strobe.enabled`} type="checkbox" size="large" /> Enable Loop
                        </Form.Item>
                </Popover>   */}
                <Divider />
                <Title level={4}>Font</Title>
                <Form.Item label="Font">
                    <Field name={'experience.font'}>
                        {({ field: { value } }) => (
                            <Select
                                placeholder={values.font || "select a font"}
                                style={{ width: 250}}
                                value={value}
                                size="large"
                                onSelect={(optionValue) => setFontValue(optionValue)}
                            >
                                {[
                                    { value: "'Anton', sans-serif;", label: "Anton" },
                                    { value: "'Arial', sans-serif;", label: "Arial" },
                                    { value: "'Bad Script', cursive;", label: "Bad Script" },
                                    { value: "'Bebas Neue', cursive;", label: "Bebas Neue" },
                                    { value: "'Benne', serif;", label: "Benne" },
                                    { value: "'Brush Script MT', cursive;", label: "Brush Script MT" },
                                    { value: "'Concert One', cursive;", label: "Concert One" },
                                    { value: "'Courier New', monospace;", label: "Courier New" },
                                    { value: "'Dancing Script', cursive;", label: "Dancing Script" },
                                    { value: "'Fredoka One', cursive;", label: "Fredoka One" },
                                    { value: "'Garamond', serif;", label: "Garamond" },
                                    { value: "'Georgia', serif;", label: "Georgia" },
                                    { value: "'Gruppo', cursive;", label: "Gruppo" },
                                    { value: "'Helvetica', sans-serif;", label: "Helvetica" },
                                    { value: "'Inconsolata', monospace;", label: "Inconsolata" },
                                    { value: "'Josefin Slab', serif;", label: "Josefin Slab" },
                                    { value: "'Lato', sans-serif;", label: "Lato" },
                                    { value: "'Montserrat', sans-serif;", label: "Montserrat" },
                                    { value: "'Open Sans Condensed', sans-serif;", label: "Open Sans" },
                                    { value: "'PT Mono', monospace;", label: "PT Mono" },
                                    { value: "'Roboto', sans-serif;", label: "Roboto" },
                                    { value: "'Slabo 27px', serif;", label: "Slabo" },
                                    { value: "'Tahoma', sans-serif;", label: "Tahoma" },
                                    { value: "'Times New Roman', serif;", label: "Times New Roman" },
                                    { value: "'Trebuchet MS', sans-serif;", label: "Trebuchet MS" },
                                    { value: "'Verdana', sans-serif;", label: "Verdana" },
                                ].map(({ value, label }) => <Option key={label} value={value}><FontStyled value={value}>{label}</FontStyled></Option>)}
                            </Select>
                        )}
                    </Field>
                </Form.Item>
            </div>
        </Drawer>
    );
}

export default SlideSettings;