import { types as slideTypes } from 'actions/slides';
import { types as experienceTypes } from 'actions/experiences';

const defaultState = 0;

const currentSlideIndexReducer = (state = 0, action) => {
    switch (action.type) {
        case experienceTypes.GET_EXPERIENCE_LOADING:
            return defaultState;
        case slideTypes.SELECT_SLIDE:
            return action.payload;
        case slideTypes.REORDER_SLIDE:
            const { currentSlideIndex, sourceIndex, destinationIndex } = action.payload;
            if (sourceIndex < currentSlideIndex && destinationIndex > currentSlideIndex) return state - 1;
            if (sourceIndex > currentSlideIndex && destinationIndex < currentSlideIndex) return state + 1;
            if (currentSlideIndex === sourceIndex) return destinationIndex;
            if (currentSlideIndex === destinationIndex) return sourceIndex;
            return state;
        default: return state;
    }
}

export default currentSlideIndexReducer;