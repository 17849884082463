import apiClient from 'lib/apiClient';

class UserService {
    async getUsers() {
        try {
            const { data: users } = await apiClient.get('/users');
            return users;
        } catch (err) {
            throw err;
        }
    }

    async resetPassword(userId) {
        try {
            const { data: { newPassword } } = await apiClient.post(`/users/${userId}/reset-password`);
            return newPassword;
        } catch (err) {
            throw err;
        }
    }
}

export default UserService;