import React, { useState } from 'react';
import isImage from 'is-image';
import MediaService from 'services/media';
import { fileIsImage } from 'lib/helpers';
import ImageTools from 'lib/ImageTools';
import { Upload, Button, message } from "antd";
import ImgCrop from 'antd-img-crop';
import { LoadingOutlined, PlusOutlined, FileDoneOutlined } from "@ant-design/icons";
import './FileUploader.css';

const mediaService = new MediaService();
const { Dragger } = Upload

const FileUploader = ({
    value,
    experienceId,
    onUploadSuccess,
    onRemoveFile,
    showImageCropTool = false,
    imageCropAspectRatio = 9/16,
    maxFileSize = 2,
    resizeImageWidth = 1080,
    resizeImageHeight = 1920,
    uploadText = "Click or drag file to this area to upload",
    accept = "image/*,video/*",
    validations = [],
}) => {
    const [loading, setLoading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(null);

    const validate = function (file) {
        const allValidations = [
            {
                criteria: file.size / 1024 / 1024 < maxFileSize,
                error: `Please choose a file that is ${maxFileSize}MB or smaller.`,
            },
            ...validations,
        ]
        const isValid = allValidations.every(validation => validation.criteria);

        if (!isValid) {
            allValidations.forEach(validation => {
                if(!validation.criteria) message.error(validation.error);
            });
        }

        return isValid
    }

    const onUploadProgress = e => {
        const newUploadPercentage = Math.round(e.loaded / e.total * 100) + '%';
        setUploadPercentage(newUploadPercentage)
    };

    const processFile = async ({ file }) => {
        if (validate(file)) {
            let media;

            try {
                // get signed s3 request
                const { signedRequest, media: newMedia } = await mediaService.getSignedS3Request(experienceId, file);
                media = newMedia;
                setLoading(true);

                // upload file
                if (fileIsImage(file)) {
                    const imageTools = new ImageTools();
                    const resizedImage = await imageTools.resize(file, { width: resizeImageWidth, height: resizeImageHeight });
                    await mediaService.uploadFileToS3(resizedImage, signedRequest, onUploadProgress);
                } else {
                    await mediaService.uploadFileToS3(file, signedRequest, onUploadProgress);
                }

                // finish upload
                await mediaService.updateMediaStatus(media, 'UPLOAD_SUCCESS');
                onUploadSuccess(media.url);
                message.success(`File successfully uploaded!`);
            } catch (err) {
                // mark media as failed
                await mediaService.updateMediaStatus(media, 'UPLOAD_ERROR');
                message.error("There was a problem.");
            }
            setLoading(false);
            setUploadPercentage(null);
        }
    }

    const handleRemoveFile = () => {
        if (onRemoveFile) onRemoveFile();
    }

    const uploader = (
        <Dragger
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            customRequest={processFile}
            disabled={!!value}
            accept={accept}
        >
            {value && isImage(value) && !loading && <img className="image-preview" src={value} alt="avatar" />}
            {value && !isImage(value) && !loading && (
                <FileDoneOutlined className="uploader-large-icon" />
            )}
            {!value && (
                <div>
                    {loading ? (
                        <>
                            <LoadingOutlined className="uploader-large-icon" />
                            <div className="ant-upload-text">Uploading {uploadPercentage}</div>
                        </>
                    ) : (
                        <>
                            <PlusOutlined className="uploader-large-icon" />
                            <div className="ant-upload-text">{uploadText}</div>
                        </>
                    )}
                </div>
            )}
        </Dragger>
    )

    return (
        <>
            {showImageCropTool ? (
                <ImgCrop
                    aspect={imageCropAspectRatio}
                    modalTitle={"Crop image before uploading"}
                    modalOk="Upload"
                    modalWidth="50%"
                >
                    {uploader}
                </ImgCrop>
            ) : uploader}
            {value && (
                <Button className="remove-file" danger type="text" onClick={handleRemoveFile}>
                    Remove File
                </Button>
            )}
        </>
    )
}

export default FileUploader
