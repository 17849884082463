import React from 'react';
import { Field } from 'formik';
import { Row, Col, Slider, InputNumber } from 'antd';

const SliderField = ({ name, min, max, step, tipFormatter, defaultValue }) => {
    return (
        <Field name={name}>
            {({ field: { value }, form: { setFieldValue } }) => (
                <Row>
                    <Col span={12}>
                        <Slider
                            min={min}
                            max={max}
                            step={step}
                            onChange={value => setFieldValue(name, value)}
                            tipFormatter={tipFormatter}
                            value={value || defaultValue}
                        />
                    </Col>
                    <Col span={4}>
                        <InputNumber
                            disabled
                            min={min}
                            max={max}
                            step={step}
                            style={{ margin: '0 16px' }}
                            value={value || defaultValue}
                            formatter={tipFormatter}
                        />
                    </Col>
                </Row>
            )}
        </Field>
    )
}

export default SliderField;