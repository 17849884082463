export const defaultState = {};
const loadingReducer = (state = defaultState, action) => {
    const { type } = action;
    const matches = /(.*)_(LOADING|SUCCESS|ERROR)/.exec(type);

    if (!matches) return state

    const [, requestName, requestState] = matches
    return {
        ...state,
        [requestName]: requestState === 'LOADING',
    }
}

export default loadingReducer;