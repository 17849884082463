import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { canAccessWithRole } from 'lib/auth';
import { userType } from 'lib/propTypes';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { message } from 'antd';

export const ProtectedRoute = ({ loading, currentUser, requiredRole, component: Component, ...rest }) => {
    const notSignedIn = !currentUser;
    if (notSignedIn) message.error('Please sign in.');

    const accessDenied = currentUser && !canAccessWithRole(currentUser.role, requiredRole);
    if (accessDenied) message.error('Access denied.');

    return (
        <Fragment>
            {!loading && (
                <Route
                    {...rest}
                    render={props => {
                        if (notSignedIn) return <Redirect to={{ pathname: "/login", state: { from: props.location }}} />
                        if (accessDenied) return <Redirect to={{ pathname: "/", state: { from: props.location }}} />

                        return <Component {...props} />
                    }}
                />
            )}
        </Fragment>
    )
};

ProtectedRoute.propTypes = {
    loading: PropTypes.bool,
    currentUser: userType,
    component: PropTypes.elementType.isRequired
}

const mapStateToProps = ({
    auth: { currentUser },
    loading: { LOG_IN }
}) => ({ loading: LOG_IN, currentUser });
export default connect(mapStateToProps)(ProtectedRoute);