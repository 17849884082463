import React from 'react';

import { Form, Input, Select } from 'antd';
import { FastField } from 'formik';
import ColorPickerField from 'components/Dashboard/ColorPickerField';

const { Option } = Select;

const Text = ({ dataPath, touched, errors }) => (
    <>
        <Form.Item
            label="Text"
            // help={touched?.text && errors?.text}
            // hasFeedback={touched?.text}
            // validateStatus={touched?.text && errors?.text ? 'error' : 'success'}
        >
            <FastField name={`${dataPath}.text`} as={Input} size="large" placeholder="What would you like to say?" />
        </Form.Item>
        <Form.Item label="Color">
            <ColorPickerField name={`${dataPath}.style.color`} />
        </Form.Item>
        <Form.Item label="Size">
            <FastField name={`${dataPath}.style.font-size`}>
                {({ field: { value }, form: { setFieldValue } }) => (
                    <Select
                        placeholder="Select a size"
                        style={{ width: 200 }}
                        value={value}
                        size="large"
                        onSelect={(optionValue) => setFieldValue(`${dataPath}.style.font-size`, optionValue)}
                    >
                        {[
                            { value: '1em', label: "Extra Small" },
                            { value: '2em', label: "Small" },
                            { value: '3em', label: "Medium" },
                            { value: '4em', label: "Large" },
                            { value: '4.5em', label: "Extra Large" },
                        ].map(({ value, label }, idx) => <Option key={idx} value={value}>{label}</Option>)}
                    </Select>
                )}
            </FastField>
        </Form.Item>
        <Form.Item label="Horizontal Alignment">
            <FastField name={`${dataPath}.style.text-align`}>
                {({ field: { value }, form: { setFieldValue } }) => (
                    <Select
                        placeholder="Select an alignment"
                        style={{ width: 200 }}
                        value={value}
                        size="large"
                        onSelect={(optionValue) => setFieldValue(`${dataPath}.style.text-align`, optionValue)}
                    >
                        {[
                            { value: 'left', label: "Left" },
                            { value: 'center', label: "Center" },
                            { value: 'right', label: "Right" },
                        ].map(({ value, label }, idx) => <Option key={idx} value={value}>{label}</Option>)}
                    </Select>
                )}
            </FastField>
        </Form.Item>
        <Form.Item label="Vertical Alignment">
            <FastField name={`${dataPath}.style.align-self`}>
                {({ field: { value }, form: { setFieldValue } }) => (
                    <Select
                        placeholder="Select an alignment"
                        style={{ width: 200 }}
                        value={value}
                        size="large"
                        onSelect={(optionValue) => setFieldValue(`${dataPath}.style.align-self`, optionValue)}
                    >
                        {[
                            { value: 'flex-start', label: "Top" },
                            { value: 'center', label: "Middle" },
                            { value: 'flex-end', label: "Bottom" },
                        ].map(({ value, label }, idx) => <Option key={idx} value={value}>{label}</Option>)}
                    </Select>
                )}
            </FastField>
        </Form.Item>
    </>
);

export default Text;