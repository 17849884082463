// these are the validation schemas for the experience editor form. It it used with Formik to
// prevent submitting the form if any of the below specified schema fields are deemed "invalid"
// and it is also used to highlight where in the experience there are errors.
// For more info, check out the Yup docs: https://github.com/jquense/yup

import { object, array, string } from 'yup';
import validator from 'validator';

const linkSchema = object({
    prompt: string().when('imgSrc', {
        is: val => !val,
        then: string().required('Prompt is required if no image is being used.')
    }),
    url: string().test({
        test: value => validator.isURL(value || '', { protocols: ['http', 'https'] }),
        message: "Please enter a valid URL"
    }),
    imgSrc: string(),
})

const dropletSchema = object({
    type: string(),
    data: object()
        .when('type', { is: 'SHOW_LINK', then: linkSchema })
})

export const experienceSchema = object({
    name: string(),
    slides: array().of(object({
        title: string(),
        droplets: array().of(dropletSchema)
    }))
});