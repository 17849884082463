import apiClient from 'lib/apiClient';

class ExperienceService {
    getExperiences() {
        return apiClient.get('/experiences');
    }

    getExperience(id) {
        return apiClient.get(`/experiences/${id}`);
    }

    createExperience(values) {
        return apiClient.post('/experiences', values);
    }

    updateExperience(experience) {
        return apiClient.put(`/experiences/${experience._id}`, experience);
    }

    duplicateExperience(id) {
        return apiClient.post(`/experiences/${id}/duplicate`);
    }

    deleteExperience(id) {
        return apiClient.delete(`/experiences/${id}`);
    }
};

export default ExperienceService;