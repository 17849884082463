import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { getExperiences, createExperience, duplicateExperience, deleteExperience } from "actions/experiences";
import { Row, Col, Card, Tooltip, Popconfirm } from "antd";
import { PlusOutlined, EditOutlined, CopyOutlined, DeleteOutlined } from '@ant-design/icons';

const columns = 4;
const { Meta } = Card;

const Experiences = ({
    getExperiences,
    createExperience,
    duplicateExperience,
    deleteExperience,
    experiences
}) => {
    useEffect(() => {
        getExperiences();
    }, [getExperiences]);

    const history = useHistory();
    const sortedExperiences = experiences.reverse();

    // add null placeholder for "new experience" card at the beginning of the list
    const experienceRows = _.chunk([null, ...sortedExperiences], columns);

    const redirectToExperienceEditor = (id) => {
        history.push(`/dashboard/experiences/${id}/edit`);
    }

    const handleClickNewExperience = () => {
        createExperience().then(experience => {
            redirectToExperienceEditor(experience._id);
        });
    }

    const handleClickDuplicateExperience = (id) => {
        duplicateExperience(id).then(duplicateExperience => {
            redirectToExperienceEditor(duplicateExperience._id);
        });
    }

    const handleClickEditExperience = (experience) => {
        redirectToExperienceEditor(experience._id);
    }

    const handleClickDeleteExperience = (id) => {
        deleteExperience(id);
    }

    const renderCardActions = (experience) => {
        return [
            <Tooltip title="Edit" mouseEnterDelay={0.5}>
                <EditOutlined
                    key="edit"
                    onClick={handleClickEditExperience.bind(this, experience)}
                    style={{fontSize: '24px'}}
                />
            </Tooltip>,
            <Tooltip title="Duplicate" mouseEnterDelay={0.5}>
                <CopyOutlined
                    key="delete"
                    onClick={handleClickDuplicateExperience.bind(this, experience._id)}
                    style={{fontSize: '24px'}}
                />
            </Tooltip>,
            <Popconfirm
                title="Delete this experience?"
                onConfirm={handleClickDeleteExperience.bind(this, experience._id)}
                okText="Yes"
                cancelText="No"
            >
                <DeleteOutlined
                    key="delete"
                    twoToneColor="#ff0000"
                    style={{fontSize: '24px'}}
                />
            </Popconfirm>
        ]
    }

    return (
        <>
            {experienceRows.map((row, idx) => (
                <Row key={idx}>
                    {idx === 0 && (
                        <Col span={24 / columns}>
                            <Card
                                hoverable
                                style={{margin:'24px 16px', textAlign: 'center'}}
                                onClick={handleClickNewExperience}
                            >
                                <PlusOutlined style={{color: '#cccccc', fontSize: '2em'}} />
                            </Card>
                        </Col>
                    )}
                    {row.map((experience, idx) => (
                        experience !== null && (
                            <Col span={24 / columns} key={idx}>
                                <Card style={{margin:'16px'}} actions={renderCardActions(experience)}>
                                    <Meta
                                        title={experience.name}
                                        description={`${experience.slides.length} slides`}
                                    />
                                </Card>
                            </Col>
                        )
                    ))}
                </Row>
            ))}
        </>
    );
};

const mapStateToProps = ({ experiences }) => ({ experiences });
const mapDispatchToProps = {
    getExperiences,
    createExperience,
    duplicateExperience,
    deleteExperience
};
export default connect(mapStateToProps, mapDispatchToProps)(Experiences);