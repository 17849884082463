import ExperienceService from '../services/experience';
import { message } from 'antd';

export const types = {
    GET_EXPERIENCE_LOADING: 'GET_EXPERIENCE_LOADING',
    GET_EXPERIENCE_ERROR: 'GET_EXPERIENCE_ERROR',
    GET_EXPERIENCE_SUCCESS: 'GET_EXPERIENCE_SUCCESS',

    GET_EXPERIENCES_LOADING: 'GET_EXPERIENCES_LOADING',
    GET_EXPERIENCES_ERROR: 'GET_EXPERIENCES_ERROR',
    GET_EXPERIENCES_SUCCESS: 'GET_EXPERIENCES_SUCCESS',

    CREATE_EXPERIENCE_LOADING: 'CREATE_EXPERIENCE_LOADING',
    CREATE_EXPERIENCE_ERROR: 'CREATE_EXPERIENCE_ERROR',
    CREATE_EXPERIENCE_SUCCESS: 'CREATE_EXPERIENCE_SUCCESS',

    EDIT_EXPERIENCE: 'EDIT_EXPERIENCE',

    UPDATE_EXPERIENCE_LOADING: 'UPDATE_EXPERIENCE_LOADING',
    UPDATE_EXPERIENCE_ERROR: 'UPDATE_EXPERIENCE_ERROR',
    UPDATE_EXPERIENCE_SUCCESS: 'UPDATE_EXPERIENCE_SUCCESS',

    DUPLICATE_EXPERIENCE_LOADING: 'DUPLICATE_EXPERIENCE_LOADING',
    DUPLICATE_EXPERIENCE_ERROR: 'DUPLICATE_EXPERIENCE_ERROR',
    DUPLICATE_EXPERIENCE_SUCCESS: 'DUPLICATE_EXPERIENCE_SUCCESS',

    DELETE_EXPERIENCE_LOADING: 'DELETE_EXPERIENCE_LOADING',
    DELETE_EXPERIENCE_ERROR: 'DELETE_EXPERIENCE_ERROR',
    DELETE_EXPERIENCE_SUCCESS: 'DELETE_EXPERIENCE_SUCCESS',
}

const experienceService = new ExperienceService();

const getExperienceLoading = () => ({ type: types.GET_EXPERIENCE_LOADING });
const getExperienceError = (message) => ({ type: types.GET_EXPERIENCE_ERROR, payload: message });
const getExperienceSuccess = (experience) => ({ type: types.GET_EXPERIENCE_SUCCESS, payload: experience });

export function getExperience(id) {
    return (dispatch) => {
        dispatch(getExperienceLoading());
        return experienceService.getExperience(id)
            .then(({ data }) => {
                dispatch(getExperienceSuccess(data));
                return data
            })
            .catch((err) => {
                dispatch(getExperienceError("There was a problem retrieving the experience."));
                message.error("There was a problem retrieving the experience.")
                throw err;
            });
    }
}

const getExperiencesLoading = () => ({ type: types.GET_EXPERIENCES_LOADING });
const getExperiencesError = (message) => ({ type: types.GET_EXPERIENCES_ERROR, payload: message });
const getExperiencesSuccess = (experiences) => ({ type: types.GET_EXPERIENCES_SUCCESS, payload: experiences });

export function getExperiences() {
    return (dispatch) => {
        dispatch(getExperiencesLoading());
        const hideLoadingMessage = message.loading("Loading...", 0);
        return experienceService.getExperiences()
            .then(({ data }) => {
                dispatch(getExperiencesSuccess(data));
                hideLoadingMessage();
                return data
            })
            .catch(() => {
                dispatch(getExperiencesError("There was a problem retrieving your experiences."));
                hideLoadingMessage();
                message.error("There was a problem retrieving your experiences.")
                return null;
            });
    }
}

const createExperienceLoading = () => ({ type: types.CREATE_EXPERIENCE_LOADING });
const createExperienceError = (message) => ({ type: types.CREATE_EXPERIENCE_ERROR, payload: message });
const createExperienceSuccess = (experience) => ({ type: types.CREATE_EXPERIENCE_SUCCESS, payload: experience });

export function createExperience(values) {
    return (dispatch) => {
        dispatch(createExperienceLoading());
        message.loading({content: "Loading...", key: "create-experience"});
        return experienceService.createExperience(values)
            .then(({ data }) => {
                dispatch(createExperienceSuccess(data));
                message.success({ content: "New experience created!", key: "create-experience", duration: 2});
                return data;
            })
            .catch(() => {
                dispatch(createExperienceError("There was a problem creating the experience."));
                message.error("There was a problem creating the experience.");
                return null;
            });
    }
}

export const editExperience = (values) => ({ type: types.EDIT_EXPERIENCE, payload: values });

const updateExperienceLoading = () => ({ type: types.UPDATE_EXPERIENCE_LOADING });
const updateExperienceError = (message) => ({ type: types.UPDATE_EXPERIENCE_ERROR, payload: message });
const updateExperienceSuccess = (experience) => ({ type: types.UPDATE_EXPERIENCE_SUCCESS, payload: experience });

export function updateExperience(values) {
    return (dispatch) => {
        dispatch(updateExperienceLoading());
        return experienceService.updateExperience(values)
            .then(({ data: experience }) => {
                message.success(`${values.name} updated!`)
                dispatch(updateExperienceSuccess(experience));
                return values;
            })
            .catch(() => {
                dispatch(updateExperienceError("There was a problem saving the experience."));
                message.error("There was a problem saving the experience.");
                return null;
            });
    }
}

const duplicateExperienceLoading = () => ({ type: types.DUPLICATE_EXPERIENCE_LOADING });
const duplicateExperienceError = (message) => ({ type: types.DUPLICATE_EXPERIENCE_ERROR, payload: message });
const duplicateExperienceSuccess = (experience) => ({ type: types.DUPLICATE_EXPERIENCE_SUCCESS, payload: experience });

export function duplicateExperience(id) {
    return (dispatch) => {
        dispatch(duplicateExperienceLoading());
        return experienceService.duplicateExperience(id)
            .then(({ data: experience }) => {
                message.success({ content: "New experience created!", key: "create-experience", duration: 2});
                dispatch(duplicateExperienceSuccess(experience));
                return experience;
            })
            .catch(() => {
                dispatch(duplicateExperienceError("There was a problem duplicating the experience."));
                message.error("There was a problem duplicating the experience.");
                return null;
            });
    }
}

const deleteExperienceLoading = () => ({ type: types.DELETE_EXPERIENCE_LOADING });
const deleteExperienceError = (message) => ({ type: types.DELETE_EXPERIENCE_ERROR, payload: message });
const deleteExperienceSuccess = (experience) => ({ type: types.DELETE_EXPERIENCE_SUCCESS, payload: experience });

export function deleteExperience(id) {
    return async (dispatch) => {
        dispatch(deleteExperienceLoading());
        const hideLoadingMessage = message.loading("Loading...", 0);
        try {
            const { data: deletedExperience } = await experienceService.deleteExperience(id);
            dispatch(deleteExperienceSuccess(deletedExperience));
            message.info({ content: `"${deletedExperience.name}" deleted.`, key: "delete-experience", duration: 2});
        } catch ({ response: { status } }) {
            const errorMessage = "There was a problem deleting that experience.";
            dispatch(deleteExperienceError(errorMessage));
            message.error(errorMessage)
        }
        hideLoadingMessage();
    }
}