import React from "react";
import { Form, Input, Button } from "antd";
import { UserOutlined, LockOutlined } from '@ant-design/icons';

const AccountForm = ({ onSubmit, loading, initialValues }) => {
    return (
        <Form
            className="login-form"
            name="basic"
            onFinish={onSubmit}
            initialValues={initialValues}
            layout="vertical"
        >
            <Form.Item
                name="email"
                label="Email"
                rules={[{ required: true, message: 'Email is required!' }]}
            >
                <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    type="email"
                    placeholder="New Email"
                />
            </Form.Item>
            <Form.Item
                name="confirmEmail"
                dependencies={['email']}
                hasFeedback
                rules={[
                    ({ getFieldValue }) => ({
                        validator: (rule, value) => {
                            console.log(initialValues)
                            const email = getFieldValue('email');
                            if (
                                (email === initialValues.email && (!value || value === email)) ||
                                (email !== initialValues.email && value === email)
                            ) {
                                return Promise.resolve();
                            }
                            return Promise.reject('The two emails that you entered do not match!');
                        },
                    }),
                ]}
            >
                <Input
                    size="large"
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    type="email"
                    placeholder="Re-Type Email"
                />
            </Form.Item>
            <Form.Item name="password" label="Password">
                <Input.Password
                    size="large"
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="New Password"
                />
            </Form.Item>
            <Form.Item
                name="confirmPassword"
                dependencies={['password']}
                hasFeedback
                rules={[
                    ({ getFieldValue }) => ({
                        validator: (rule, value) => {
                            const password = getFieldValue('password');
                            if (!password || value === password) return Promise.resolve();
                            return Promise.reject('The two passwords that you entered do not match!');
                        },
                    }),
                ]}
            >
                <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Re-Type Password"
                    size="large"
                />
            </Form.Item>
            <Form.Item>
                <Button type="primary" htmlType="submit">
                    Update Account
                </Button>
            </Form.Item>
        </Form>
    );
};

export default AccountForm;