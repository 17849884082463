import React from 'react';
import { Field, useFormikContext } from 'formik';
import { Drawer, Typography, Form, Input, Divider, Select } from 'antd';
import ColorPickerField from 'components/Dashboard/ColorPickerField';
import SliderField from 'components/Dashboard/SliderField';
import FileUploader from 'components/Dashboard/FileUploader';
import HelpToolTip from '../../../../../HelpToolTip';

const { Title } = Typography;
const { Option } = Select;

const SlideSettings = ({ experienceId, slideIndex, visible, onClose }) => {
    const { values } = useFormikContext();
    const slidePath = `slides[${slideIndex}]`

    return (
        <Drawer
            title="Slide Settings"
            placement="left"
            closable={false}
            onClose={onClose}
            visible={visible}
            width={450}
        >
            <div className="ant-form ant-form-vertical">
                <Form.Item label={<>
                    Title
                    <HelpToolTip
                        placement="right"
                        content='Give this slide a name for reference. Only you will see this.'
                        style={{marginLeft: '5px'}}
                    />
                </>}>
                    <Field name={`${slidePath}.title`} as={Input} size="large" placeholder="Slide Title" />
                </Form.Item>

                <Form.Item label={<>
                    Key Color
                    <HelpToolTip
                        placement="right"
                        content='You can color code your slides with "Key Color". Only you will see this.'
                        style={{marginLeft: '5px'}}
                    />
                </>}>
                    <ColorPickerField name={`${slidePath}.keyColor`} />
                </Form.Item>

                <Divider />

                <Title level={4}>Background Settings</Title>

                <Form.Item label="Background Color">
                    <ColorPickerField name={`${slidePath}.background.color.value`} />
                </Form.Item>

                <Form.Item label="Background Image">
                    <Field name={`${slidePath}.background.imageUrl`}>
                        {({ field: { value }, form: { setFieldValue }}) => (
                            <FileUploader
                                experienceId={experienceId}
                                onUploadSuccess={url => setFieldValue(`${slidePath}.background.imageUrl`, url)}
                                onRemoveFile={() => setFieldValue(`${slidePath}.background.imageUrl`, '')}
                                value={value}
                                showImageCropTool={true}
                                accept="image/*"
                            />
                        )}
                    </Field>
                </Form.Item>

                <Form.Item label={<>
                    Strobe Options
                    <HelpToolTip
                        placement="right"
                        content='Bring your background to life with a pulsing strobe. You can also choose the speed below.'
                        style={{marginLeft: '5px'}}
                    />
                </>}>
                    <Field name={`${slidePath}.background.color.strobe.enabled`} type="checkbox" size="large" /> Enable Strobe
                </Form.Item>

                <Form.Item label="Strobe Speed">
                    <Field name={`${slidePath}.background.color.strobe.rate`}>
                        {({ field: { value }, form: { setFieldValue } }) => (
                            <Select
                                disabled={!values.slides[slideIndex].background?.color?.strobe?.enabled}
                                placeholder="Select a strobe speed"
                                style={{ width: 200 }}
                                value={value}
                                size="large"
                                onSelect={(optionValue) => setFieldValue(`${slidePath}.background.color.strobe.rate`, optionValue)}
                            >
                                {[
                                    { rate: 0.25, label: "Extra Slow" },
                                    { rate: 0.5, label: "Slow" },
                                    { rate: 0.75, label: "Medium" },
                                    { rate: 1.5, label: "Fast" },
                                    { rate: 2, label: "Extra Fast" },
                                ].map(({ rate, label }) => <Option key={label} value={rate}>{label}</Option>)}
                            </Select>
                        )}
                    </Field>
                </Form.Item>

                <Divider />

                <Title level={4}>Autoplay Settings</Title>

                <Form.Item label={<>
                    Autoplay Duration (seconds)
                    <HelpToolTip
                        placement="right"
                        content='When autoplay is enabled from the controller, how long should splashmob stay on this slide?'
                        style={{marginLeft: '5px'}}
                    />
                </>}>
                    <SliderField
                        name={`${slidePath}.autoplay.duration`}
                        min={1000}
                        max={30000}
                        step={1000}
                        tipFormatter={value => `${value / 1000} sec.`}
                        defaultValue={5000}
                    />
                </Form.Item>
            </div>
        </Drawer>
    );
}

export default SlideSettings;