import UserService from '../services/user';
import { message } from 'antd';

export const types = {
    GET_USERS_LOADING: 'GET_USERS_LOADING',
    GET_USERS_ERROR: 'GET_USERS_ERROR',
    GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',

    RESET_USER_PASSWORD_LOADING: 'RESET_USER_PASSWORD_LOADING',
    RESET_USER_PASSWORD_ERROR: 'RESET_USER_PASSWORD_ERROR',
    RESET_USER_PASSWORD_SUCCESS: 'RESET_USER_PASSWORD_SUCCESS',
}

const userService = new UserService();

const getUsersLoading = () => ({ type: types.GET_USERS_LOADING });
const getUsersError = (message) => ({ type: types.GET_USERS_ERROR, payload: message });
const getUsersSuccess = (users) => ({ type: types.GET_USERS_SUCCESS, payload: users });

export function getUsers() {
    return async (dispatch) => {
        dispatch(getUsersLoading());
        const hideLoadingMessage = message.loading("Loading...", 0);
        try {
            const users = await userService.getUsers();
            dispatch(getUsersSuccess(users));
        } catch ({ response: { status } }) {
            const errorMessage = "There was a problem retrieving users.";
            dispatch(getUsersError(errorMessage));
            message.error(errorMessage)
        }
        hideLoadingMessage();
    }
}

const resetUserPasswordLoading = () => ({ type: types.RESET_USER_PASSWORD_LOADING });
const resetUserPasswordError = () => ({ type: types.RESET_USER_PASSWORD_ERROR });
const resetUserPasswordSuccess = () => ({ type: types.RESET_USER_PASSWORD_SUCCESS });

export function resetUserPassword(userId) {
    return async (dispatch) => {
        dispatch(resetUserPasswordLoading());
        const hideLoadingMessage = message.loading("Loading...", 0);
        try {
            const newPassword = await userService.resetPassword(userId);
            dispatch(resetUserPasswordSuccess());
            hideLoadingMessage();
            return newPassword;
        } catch (err) {
            dispatch(resetUserPasswordError());
            message.error("There was a problem reseting the user's password.");
            hideLoadingMessage();
            return false;
        }
    }
}