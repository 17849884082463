import { omit } from 'lodash';
import merge from 'deepmerge';
import { types } from 'actions/experiences';

const defaultState = null;
const experienceReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.GET_EXPERIENCE_LOADING:
            return defaultState;
        case types.CREATE_EXPERIENCE_SUCCESS:
        case types.GET_EXPERIENCE_SUCCESS:
        case types.UPDATE_EXPERIENCE_SUCCESS:
            return omit(action.payload, ['createdAt', 'updatedAt', '__v']);

        case types.EDIT_EXPERIENCE:
            return merge(state, action.payload, { arrayMerge: (_, srcArray) => srcArray });

        default: return state;
    }
}

export default experienceReducer;