import { combineReducers } from 'redux';
import currentSlideIndexReducer from './currentSlideIndex';
import currentDropletIndexReducer from './currentDropletIndex';
import experienceReducer from './experience';

export default combineReducers({
    currentSlideIndex: currentSlideIndexReducer,
    currentDropletIndex: currentDropletIndexReducer,
    experience: experienceReducer,
});
