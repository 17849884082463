import AudioSample from './AudioSample';
import Image from './Image';
import Video from './Video';
import Link from './Link';
import Poll from './Poll';
import CustomHTML from './CustomHTML';
import TextInput from './TextInput';
import AugmentedReality from './AugmentedReality';
import Text from './Text';

const DropletFormFields = {
    AudioSample,
    Image,
    Video,
    Link,
    Poll,
    CustomHTML,
    TextInput,
    Text,
    AugmentedReality,
}

export default DropletFormFields;