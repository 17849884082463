// returns a formatted (cleaned) version of the experience editor submission data by:
// for example, when possible, prepend link urls with 'http' if not present.
// Cleaning can by done by droplet type in formatDroplet() below
export const formatValidSubmission = (values) => {
    return {
        ...values,
        slides: values.slides.map(slide => formatSlide(slide))
    };
}

const formatSlide = slide => {
    return {
        ...slide,
        droplets: slide.droplets.map(droplet => formatDroplet(droplet))
    }
}

const formatDroplet = droplet => {
    const { type, data } = droplet;
    const formattedDropletData = {
        'SHOW_LINK': {
            ...data,
            url: !/^https?:\/\//i.test(data.url) ? 'http://' + data.url : data.url
        }
    }

    return { ...droplet, data: formattedDropletData[type] || data }
}