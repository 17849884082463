import queryString from 'query-string';
import apiClient from 'lib/apiClient';

class MediaService {
    async getSignedS3Request(experienceId, file) {
        try {
            const query = queryString.stringify({
                experienceId,
                fileName: file.name,
                fileType: file.type,
                mediaType: 'EXPERIENCE_CONTENT',
            });
            const { data: { signedRequest, media } } = await apiClient({
                method: 'get',
                url: `/media/signed-s3-url?${query}`,
            });
            return { signedRequest, media };
        } catch (err) {
            throw err;
        }
    }

    async uploadFileToS3(file, signedRequest, onProgress) {
        return new Promise((resolve, reject) => {
            const xhr = new XMLHttpRequest();
            xhr.upload.onprogress = onProgress;
            xhr.onreadystatechange = async () => {
                if(xhr.readyState === 4){
                    if(xhr.status === 200) resolve(true)
                    else reject(false);
                }
            };
            xhr.open('PUT', signedRequest);
            xhr.send(file);
        });
    }

    async updateMediaStatus(media, status) {
        try {
            const query = queryString.stringify({
                mediaId: media._id,
                mediaStatus: status,
            });
            const { data: { media: updatedMedia } } = await apiClient({
                method: 'put',
                url: `/media?${query}`,
            });
            return updatedMedia;
        } catch (err) {
            throw err;
        }
    }
}

export default MediaService;