import React from 'react';
import { Form, Select } from 'antd';
import { FastField } from 'formik';
import FileUploader from 'components/Dashboard/FileUploader';

const { Option } = Select;

const Image = ({ experienceId, dataPath }) => (
    <>
        <Form.Item label="Image">
            <FastField name={`${dataPath}.url`}>
                {({ field: { value }, form: { setFieldValue }}) => (
                    <FileUploader
                        experienceId={experienceId}
                        onUploadSuccess={url => setFieldValue(`${dataPath}.url`, url)}
                        onRemoveFile={() => setFieldValue(`${dataPath}.url`, '')}
                        value={value}
                        accept="image/*"
                    />
                )}
            </FastField>
        </Form.Item>
        <Form.Item label="Vertical Alignment">
            <FastField name={`${dataPath}.style.align-self`}>
                {({ field: { value }, form: { setFieldValue } }) => (
                    <Select
                        placeholder="Select an alignment"
                        style={{ width: 200 }}
                        defaultValue="center"
                        value={value}
                        size="large"
                        onSelect={optionValue => setFieldValue(`${dataPath}.style.align-self`, optionValue)}
                    >
                        {[
                            { value: 'flex-start', label: "Top" },
                            { value: 'center', label: "Middle" },
                            { value: 'flex-end', label: "Bottom" },
                        ].map(({ value, label }, idx) => <Option key={idx} value={value}>{label}</Option>)}
                    </Select>
                )}
            </FastField>
        </Form.Item>
    </>
);

export default Image;