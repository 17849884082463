import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useFormikContext } from 'formik';
import { Draggable } from 'react-beautiful-dnd';
import SlideCard from './SlideCard';
import SlideSettings from './SlideSettings';
import './Slide.css';

const Slide = ({
    experience, experienceId, slide, slideIndex, selected: slideSelected,
    onSlideClick, onDeleteSlideClick, onDuplicateSlideClick,
}) => {

    const [settingsVisible, setSettingsVisible] = useState(false);
    const { setFieldValue, errors, touched } = useFormikContext();

    const handleShowSettings = () => setSettingsVisible(true);

    const handleHideSettings = () => setSettingsVisible(false);

    const handlePickColor = (color) => {
        setFieldValue(`slides[${slideIndex}].keyColor`, color.hex)
    }

    const handleSlideClick = () => {
        onSlideClick();
    }

    const handleDeleteClick = (evt) => {
        evt.stopPropagation();
        onDeleteSlideClick(slideIndex);
    }

    const handleDuplicateClick = (evt) => {
        evt.stopPropagation();
        onDuplicateSlideClick(slideIndex);
    }
    const slideHasErrors = !!errors.slides && !!errors.slides[slideIndex] && !!errors.slides[slideIndex].droplets
    const slideHasTouchedDroplets = !!touched.slides && !!touched.slides[slideIndex] && !!touched.slides[slideIndex].droplets
    const slideHasTouchedDropletsWithErrors = slideHasErrors && slideHasTouchedDroplets && errors.slides[slideIndex].droplets.find((droplet, dropletIdx) => {
        return droplet && !!Object.keys(droplet.data).find(key => touched.slides[slideIndex].droplets[dropletIdx].data[key]) || 1;
    });

    return (
        <Draggable draggableId={String(slideIndex)} index={slideIndex}>
            {provided => (
                <div className="slide"
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <SlideCard
                        experience={experience}
                        slideIndex={slideIndex}
                        slide={slide}
                        onColorPick={handlePickColor}
                        onShowSettings={handleShowSettings}
                        onDuplicate={handleDuplicateClick}
                        onDelete={handleDeleteClick}
                        selected={slideSelected}
                        onClick={handleSlideClick}
                        showError={slideHasTouchedDropletsWithErrors}
                    />
                    <SlideSettings
                        experienceId={experienceId}
                        slideIndex={slideIndex}
                        onClose={handleHideSettings}
                        visible={settingsVisible}
                    />
                </div>
            )}
        </Draggable>
    );
}

const mapStateToProps = ({
    editor: { experience: { _id: experienceId } }
}) => ({ experienceId });

export default connect(mapStateToProps)(Slide);
