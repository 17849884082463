import { types } from 'actions/experiences';

const defaultState = []
const experiencesReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.GET_EXPERIENCES_SUCCESS:
            return action.payload;
        case types.DELETE_EXPERIENCE_SUCCESS:
            return state.filter(experience => experience._id !== action.payload._id);
        default: return state;
    }
}

export default experiencesReducer;