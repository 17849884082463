import React from 'react';
import { Form, Input, Divider } from 'antd';
import { FastField } from 'formik';
import ColorPickerField from 'components/Dashboard/ColorPickerField';

const Poll = ({ dataPath }) => (
    <>
        <Form.Item label="Prompt">
            <FastField name={`${dataPath}.prompt`} as={Input} size="large" placeholder="Prompt" />
        </Form.Item>
        <Form.Item label="Option 1">
            <FastField name={`${dataPath}.options[0]`} as={Input} size="large" placeholder="Option 1" />
        </Form.Item>
        <Form.Item label="Option 2">
            <FastField name={`${dataPath}.options[1]`} as={Input} size="large" placeholder="Option 2" />
        </Form.Item>
        <Form.Item label="Option 3">
            <FastField name={`${dataPath}.options[2]`} as={Input} size="large" placeholder="Option 3" />
        </Form.Item>
        <Divider />
        <Form.Item label="Color">
            <ColorPickerField name={`${dataPath}.primaryColor`} />
        </Form.Item>
    </>
);

export default Poll;