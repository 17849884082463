import AuthService from '../services/auth';
import { message } from 'antd';

export const types = {
    LOG_IN_LOADING: 'LOG_IN_LOADING',
    LOG_IN_ERROR: 'LOG_IN_ERROR',
    LOG_IN_SUCCESS: 'LOG_IN_SUCCESS',

    SIGN_UP_LOADING: 'SIGN_UP_LOADING',
    SIGN_UP_ERROR: 'SIGN_UP_ERROR',
    SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',

    UPDATE_ACCOUNT_LOADING: 'UPDATE_ACCOUNT_LOADING',
    UPDATE_ACCOUNT_ERROR: 'UPDATE_ACCOUNT_ERROR',
    UPDATE_ACCOUNT_SUCCESS: 'UPDATE_ACCOUNT_SUCCESS',

    LOG_OUT: 'LOG_OUT',
}

const auth = new AuthService();

const logInLoading = () => ({ type: types.LOG_IN_LOADING });
const logInError = () => ({ type: types.LOG_IN_ERROR });
const logInSuccess = (user) => ({ type: types.LOG_IN_SUCCESS, payload: user });

export function logIn(credentials) {
    return (dispatch) => {
        dispatch(logInLoading());
        return auth.logIn(credentials)
            .then(user => {
                dispatch(logInSuccess(user));
                return user
            })
            .catch(() => {
                dispatch(logInError());
                message.error('Your email or password are incorrect.');
                return false;
            });
    }
}

const signUpLoading = () => ({ type: types.SIGN_UP_LOADING });
const signUpError = () => ({ type: types.SIGN_UP_ERROR });
const signUpSuccess = (user) => ({ type: types.SIGN_UP_SUCCESS, payload: user });

export function signUp(fields) {
    return (dispatch) => {
        dispatch(signUpLoading());
        return auth.signUp(fields)
            .then(user => {
                dispatch(signUpSuccess(user));
                return user
            })
            .catch(({ response: { status } }) => {
                dispatch(signUpError());
                message.error("That email is already taken."); // TODO: use status code to determine correct message
                return false;
            });
    }
}

const updateAccountLoading = () => ({ type: types.UPDATE_ACCOUNT_LOADING });
const updateAccountError = () => ({ type: types.UPDATE_ACCOUNT_ERROR });
const updateAccountSuccess = (user) => ({ type: types.UPDATE_ACCOUNT_SUCCESS, payload: user });

export function updateAccount(fields) {
    return (dispatch) => {
        dispatch(updateAccountLoading());
        return auth.updateAccount(fields)
            .then(user => {
                dispatch(updateAccountSuccess(user));
                message.success("Account updated.")
                return user
            })
            .catch(response => {
                dispatch(updateAccountError());
                message.error("That email is already taken."); // TODO: use status code to determine correct message
                return false;
            });
    }
}

export function getCurrentUser() {
    return (dispatch) => {
        dispatch(logInLoading());
        return auth.getCurrentUser()
            .then(user => dispatch(logInSuccess(user)))
            .catch(({ response }) => dispatch(logInError()));
    }
}

export function logOut() {
    return (dispatch) => {
        auth.logOut();
        dispatch({ type: types.LOG_OUT });
        message.info("You've logged out successfully.");
    }
}