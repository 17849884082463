import axios from 'axios';

class HubspotService {
    async submitInviteFriendForm(fields) {
        try {
            const { REACT_APP_HUBSPOT_PORTAL_ID, REACT_APP_HUBSPOT_INVITE_FORM_GUID } = process.env;
            const formattedData = Object.keys(fields).map(field => ({
                name: field,
                value: fields[field],
            }))
            await axios({
                method: 'POST',
                url: `https://api.hsforms.com/submissions/v3/integration/submit/${REACT_APP_HUBSPOT_PORTAL_ID}/${REACT_APP_HUBSPOT_INVITE_FORM_GUID}`,
                data: { fields: formattedData },
            });
        } catch (err) {
            throw err;
        }
    }
}

export default HubspotService;