import React from 'react';
import { Layout } from "antd";
import splashmobIcon from 'assets/splashmob_icon.svg';
import './Loading.css';

export const loadingIcon = (
    <img className="loading-icon" src={splashmobIcon} alt="splashmob spinner" />
)

const Loading = () => {
    return (
        <Layout className="splashmob-loading-screen">
            {loadingIcon}
        </Layout>
    )
}

export default Loading;