import React from 'react';
import { Popover, Button } from 'antd';
import { BgColorsOutlined } from '@ant-design/icons';
import { TwitterPicker } from 'react-color';
import { Field } from 'formik';

const ColorPickerField = ({ name, placeholder = "Choose Color", size = "large" }) => {
    return (
        <Field name={name}>
            {({ field: { value }, form: { setFieldValue } }) => (
                <>

                    <Popover
                        placement='bottomLeft'
                        trigger='click'
                        overlayClassName='color-picker'
                        content={<TwitterPicker colors={[ '#000000','#707070', '#ABB8C3','#FCFCFC','#0aabf5', '#3ce3fe', '#ffef5d', '#f66559',  '#6ebd6d', '#ff6299']} onChangeComplete={(color) => setFieldValue(name, color.hex)} />}
                    >
                        <Button
                            style={{ backgroundColor: value, color: 'white' }}
                            icon={<BgColorsOutlined />}
                            size={size}
                        >
                            {value || placeholder}
                        </Button>
                    </Popover>
                </>
            )}
        </Field>
    );
}

export default ColorPickerField