import React from 'react';
import { Card, Popover, Tooltip } from 'antd';
import classNames from 'classnames';
import { BgColorsOutlined, CopyOutlined, SettingOutlined, DeleteOutlined } from '@ant-design/icons';
import { TwitterPicker } from 'react-color';
import SlideTitle from './SlideTitle';
import { PreviewThumbnail } from '@splashmob-inc/splashmob-ui';
import './SlideCard.css';
import { setLocale } from 'yup';
import styled from 'styled-components';

const SlideCard = ({
    experience,
    slideIndex,
    slide,
    onClick,
    onColorPick,
    onDuplicate,
    onShowSettings,
    onDelete,
    selected,
    showError,
}) => {
    return (
        <>  
            {slide ? (
                <Card
                    onClick={onClick}
                    headStyle={{ background: slide.keyColor }}
                    bodyStyle={{ }}
                    className={classNames('slide-item', { selected, 'has-errors': showError })}
                    actions={[
                        <Tooltip title="Duplicate" mouseEnterDelay={0.5}>
                            <CopyOutlined
                                key="delete"
                                style={{fontSize: '18px'}}
                                onClick={onDuplicate}
                            />
                        </Tooltip>,
                        <Tooltip title="Delete" mouseEnterDelay={0.5}>
                            <DeleteOutlined
                                key="delete"
                                twoToneColor="#ff0000"
                                style={{fontSize: '18px'}}
                                onClick={onDelete}
                            />
                        </Tooltip>
                    ]}>
                        <div style={{height: '289px',  width: '130px', transform: 'scale(0.2)', marginTop: '-118px', marginLeft: '-61px', pointerEvents: 'none'}} >
                            <PreviewThumbnail
                                experience={experience} 
                                slide={slide} />
                        </div>
                </Card>
            ) : null }
        </>
    )
}

export default SlideCard;