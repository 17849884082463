export const types = {
    SELECT_SLIDE: 'SELECT_SLIDE',
    REORDER_SLIDE: 'REORDER_SLIDE',
}

export const selectSlide = (slideIndex) => ({
    type: types.SELECT_SLIDE,
    payload: slideIndex
});

export const reorderSlide = (currentSlideIndex, sourceIndex, destinationIndex) => ({
    type: types.REORDER_SLIDE,
    payload: { currentSlideIndex, sourceIndex, destinationIndex },
});