import React from 'react';
import { Form, Input } from 'antd';
import { FastField } from 'formik';
import ColorPickerField from 'components/Dashboard/ColorPickerField';

const TextInput = ({ dataPath }) => (
    <>
        <Form.Item label="Prompt">
            <FastField
                name={`${dataPath}.prompt`}
                as={Input}
                size="large"
                placeholder="Prompt"
            />
        </Form.Item>
        <Form.Item label="Optional Field Placeholder">
            <FastField name={`${dataPath}.placeholder`} as={Input} size="large" placeholder="Placeholder" />
        </Form.Item>
        <Form.Item label="Color">
            <ColorPickerField name={`${dataPath}.primaryColor`} />
        </Form.Item>
    </>
);


export default TextInput;