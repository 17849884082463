import React from 'react';
import { Form, Input } from 'antd';
import { FastField } from 'formik';

const CustomHTML = ({ dataPath }) => (
    <>
        <Form.Item label="HTML">
            <FastField name={`${dataPath}.html`} as={Input.TextArea} size="large" placeholder="HTML" />
        </Form.Item>
    </>
);


export default CustomHTML;