export const roles = Object.freeze({
    USER: 'USER',
    ADMIN: 'ADMIN',
    SUPERADMIN: 'SUPERADMIN',
})

const { USER, ADMIN, SUPERADMIN } = roles;

// returns boolean: whether a user of a given role has access to anything that has a minimum permission of requiredRole.
// example:
// canAccessWithRole(SUPERADMIN, USER) -> true
// canAccessWithRole(ADMIN, SUPERADMIN) -> false
export function canAccessWithRole(role, requiredRole) {
    switch (requiredRole) {
        case USER: return [USER, ADMIN, SUPERADMIN].includes(role);
        case ADMIN: return [ADMIN, SUPERADMIN].includes(role);
        case SUPERADMIN: return [SUPERADMIN].includes(role);
        default: return true
    }
}