import React from 'react';
import { connect } from "react-redux";
import { updateAccount } from '../../actions/auth';
import { Typography, Row, Col } from 'antd';
import AccountForm from './AccountForm';

const Account = ({ currentUser, updateAccount, loading }) => {
    const handleFormSubmit = values => {
        updateAccount(values);
    }

    return (
        <Row justify="center">
            <Col flex="600px">
                <div id="log-in">
                    <Typography.Title>Account Settings</Typography.Title>
                    <AccountForm
                        onSubmit={handleFormSubmit}
                        loading={loading.LOG_IN}
                        initialValues={currentUser}
                    />
                </div>
            </Col>
        </Row>
    )
}

const mapStateToProps = ({
    auth: { currentUser },
    loading,
}) => ({ currentUser, loading });
export default connect(mapStateToProps, { updateAccount })(Account);