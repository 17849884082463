import React from "react";
import { connect } from 'react-redux';
import { Switch, Route } from "react-router-dom";
import { Layout } from "antd";
import { roles } from '../../lib/auth';
import Loading from '../Loading';
import ProtectedRoute from '../ProtectedRoute';
import NavBar from '../NavBar';
import Home from '../Home';
import Account from '../Account';
import Dashboard from '../Dashboard';
import Admin from '../Admin';
import LogIn from '../LogIn';
import NotFound from '../NotFound';

const { Content, Footer } = Layout;

const year = (new Date()).getFullYear();

const App = ({ loading }) => (
    <Layout style={{minHeight:"100vh"}}>
        <NavBar />
        <Content style={{ padding: '0 50px', display: 'flex' }}>
            {loading.LOG_IN ? <Loading /> : (
                <Layout style={{ padding: '24px' }}>
                    <Switch>
                        <Route exact path="/" component={Home} />
                        <Route exact path="/login" component={LogIn} />
                        <ProtectedRoute exact path="/admin" component={Admin} requiredRole={roles.ADMIN} />
                        <ProtectedRoute exact path="/account" component={Account} />
                        <ProtectedRoute path="/dashboard" component={Dashboard} />
                        <Route exact path="/404" component={NotFound} />
                        <Route component={NotFound} />
                    </Switch>
                </Layout>
            )}
        </Content>
        <Footer style={{ textAlign: 'center' }}> © splashmob, Inc. {year}</Footer>
    </Layout>
);

const mapStateToProps = ({ loading }) => ({ loading });
export default connect(mapStateToProps)(App);
