import { combineReducers } from 'redux';
import authReducer from './auth';
import experiencesReducer from './experiences';
import editorReducer from './editor';
import adminReducer from './admin';
import loadingReducer from './loading';

export default combineReducers({
    auth: authReducer,
    experiences: experiencesReducer,
    editor: editorReducer,
    admin: adminReducer,
    loading: loadingReducer,
});