import React from 'react';
import { Field } from 'formik';
import { Input } from 'antd';
import './ExperienceEditor.css';

const NameForm = () => {
    return (
        <div className="name-form">
            <Field id="experience-name" name="name" as={Input} size="large" />
        </div>
    )
};

export default NameForm;