import React from 'react';
import { Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

const HelpToolTip = ({ title, content, style, className, placement = 'top', size = '1em' }) => {
    return (
        <Popover
            className={className}
            title={title}
            content={content}
            placement={placement}
        >
            <InfoCircleOutlined
                twoToneColor="#ff0000"
                style={{...style, fontSize: size}}
            />
        </Popover>
    );
}

export default HelpToolTip;