import React from 'react';
import { Form } from 'antd';
import { FastField } from 'formik';
import FileUploader from 'components/Dashboard/FileUploader';

const Video = ({ experienceId, dataPath }) => (
    <>
        <Form.Item label="Video File URL">
            <FastField name={`${dataPath}.src`}>
                {({ field: { value }, form: { setFieldValue }}) => (
                    <FileUploader
                        experienceId={experienceId}
                        onUploadSuccess={url => setFieldValue(`${dataPath}.src`, url)}
                        onRemoveFile={() => setFieldValue(`${dataPath}.src`, '')}
                        value={value}
                        accept=".mp4"
                        maxFileSize={15}
                    />
                )}
            </FastField>
        </Form.Item>
        <Form.Item>
            <FastField name={`${dataPath}.fitwidth`} type="checkbox" size="large" /> Fit Width
        </Form.Item>
        <Form.Item>
            <FastField name={`${dataPath}.loop`} type="checkbox" size="large" /> Loop
        </Form.Item>
    </>
);

export default Video;