import { types as experienceTypes } from 'actions/experiences';
import { types as slideTypes } from 'actions/slides';
import { types as dropletTypes } from 'actions/droplets';

const defaultState = 0;

const currentDropletIndexReducer = (state = defaultState, action) => {
    switch (action.type) {
        case experienceTypes.GET_EXPERIENCES_LOADING:
        case slideTypes.ADD_SLIDE:
        case slideTypes.SELECT_SLIDE:
            return defaultState;

        case dropletTypes.SELECT_DROPLET:
            return action.payload;
        default: return state;
    }
}

export default currentDropletIndexReducer;