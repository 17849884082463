import React, { useEffect, useRef, useState } from "react";
import { connect } from 'react-redux';
import _ from 'lodash';
import { experienceSchema } from 'lib/editor/validation';
import { formatValidSubmission } from 'lib/editor/format';
import { slideSchema, dropletSchema } from 'lib/schema';
import { getExperience, updateExperience } from 'actions/experiences';
import { selectSlide  } from 'actions/slides';
import { selectDroplet } from 'actions/droplets';
import { Button, Spin, Popover, message, Image } from 'antd';
import { PlusOutlined, SettingOutlined, SaveOutlined, RightCircleOutlined, ShareAltOutlined } from '@ant-design/icons';
import { loadingIcon } from '../../Loading';
import { Container, Column } from './Scrollable';
import DropletsPanel from './DropletsPanel';
import SlideList from './SlideList';
import AddDropletButton from './DropletsPanel/AddDropletButton';
import './ExperienceEditor.css';
import { Preview } from '@splashmob-inc/splashmob-ui';
import { Formik, Form, FieldArray, Field } from 'formik';
import QRCode from 'qrcode';
import ExperienceSettings from './ExperienceSettings';

const ExperienceEditor = (props) => {
    const {
        editor, loading, // state
        getExperience, updateExperience, // experience actions
        selectSlide, // slide actions
        selectDroplet, // droplet actions
        match, history, // router props
    } = props;

    const { experience, currentSlideIndex } = editor;
    const formikRef = useRef(null);

    const [settingsVisible, setSettingsVisible] = useState(false);
    const [qrCodeSrc, setQrCodeSrc] = useState(null);

    const handleShowSettings = () => setSettingsVisible(true);

    const handleHideSettings = () => setSettingsVisible(false);

    const makeQRCode = (url) => {
        return new Promise((resolve, reject) => {
            // let url = `${process.env.REACT_APP_CONTROLLER_BASE_URL}/xp/${experience.shortId}`;
            QRCode.toDataURL(url, {type: 'image/png', margin: 2 }, (err, dataUrl) => {
                if (err) return reject(err);
                resolve(dataUrl)
            })
        });
    }

    useEffect(() => {
        (async () => {
            try {
                const { shortId } = await getExperience(match.params.id);
                const experienceURL = `${process.env.REACT_APP_CONTROLLER_BASE_URL}/xp/${shortId}`;
                const qrSrc = await makeQRCode(experienceURL);
                setQrCodeSrc(qrSrc);
            } catch (err) {
                switch (err.status) {
                    case 404:
                    case 400:
                        return history.push("/404");
                    default: return history.push("/");
                }
            }
        })();
    }, [getExperience, match.params.id, history]);

    const handleSlideClick = (slideIndex) => {
        if (slideIndex !== currentSlideIndex) {
            selectSlide(slideIndex);
        }
    }

    const handleValidFormSubmit = () => {
        const formattedValues = formatValidSubmission(formikRef.current.values);
        updateExperience(formattedValues);
    }

    const handleTrySubmit = () => {
        const { handleSubmit, errors } = formikRef.current;

        // current version of Formik (2.2.6) contains bug where errors object is mistakenly
        // populated with [undefined] when <FieldArray /> is re-ordered with move() method
        const errorsIsFormikBug = JSON.stringify(errors.slides) === JSON.stringify([undefined]);

        if (!_.isEmpty(errors) && !errorsIsFormikBug) {
            message.error('Please check and fix required fields before saving.')
        } else {
            handleSubmit();
        }
    }

    const copyToClipboard = () => {
        const el = document.createElement('textarea');
        el.value = `${process.env.REACT_APP_CONTROLLER_BASE_URL}/xp/${experience.shortId}`;
        el.setAttribute('readonly', '');
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        message.success('Link copied to clipboard!')
    }



    const openController = () => {
        window.open(`${process.env.REACT_APP_CONTROLLER_BASE_URL}/controller/${experience._id}`, "blank")
    }

    return (
        <Spin spinning={loading.GET_EXPERIENCE} tip="Loading experience..." indicator={loadingIcon}>
            <div className="experience-editor">
                {!loading.GET_EXPERIENCE && experience && (
                    <Formik
                        initialValues={experience}
                        onSubmit={handleValidFormSubmit}
                        innerRef={formikRef}
                        validationSchema={experienceSchema}
                        >
                        {({ values: { slides } }) => (
                            <Form style={{ display: 'flex' }}>
                                <ExperienceSettings
                                    experience={experience}
                                    onClose={handleHideSettings}
                                    visible={settingsVisible}
                                />
                                {/* <NameForm /> */}

                                <div id="links">
                                    <Popover
                                        mouseEnterDelay={0.5}
                                        placement="right"
                                        className="droplet-description"
                                        title={"Story Settings"}
                                        content='Configure the global settings of your story.'>
                                        <SettingOutlined
                                            onClick={handleShowSettings}
                                            key="settings"/>
                                    </Popover>
                                    <Popover
                                        mouseEnterDelay={0.5}
                                        placement="right"
                                        className="droplet-description"
                                        title={"Save"}
                                        content="Don't forget to save your work.">
                                        <SaveOutlined
                                            onClick={handleTrySubmit}/>
                                    </Popover>
                                    <Popover
                                        mouseEnterDelay={0.5}
                                        placement="right"
                                        className="droplet-description"
                                        title={"GO LIVE (beta)"}
                                        content='Live Controller: Real time control of all connected devices.'>
                                            <RightCircleOutlined
                                            id="go-live"
                                            target="controller"
                                            href={`${process.env.REACT_APP_CONTROLLER_BASE_URL}/controller/${experience._id}`}
                                            disabled={!experience}
                                            onClick={openController}/>
                                    </Popover>
                                    <Popover
                                        placement="left"
                                        className="droplet-description"
                                        title={"Story Link"}
                                        content='This is the link to your story. Click to copy to clipboard.'>
                                        <ShareAltOutlined
                                           id="share-link"
                                           onClick={copyToClipboard} />
                                    </Popover>
                                    <Popover
                                        placement="left"
                                        className="droplet-description"
                                        title={"QR Code"}
                                        content='The QR Code for your story. Click to download.'>
                                        <a download
                                            style={{margin: '33% auto'}}
                                            href={qrCodeSrc}
                                            id="qr-link">
                                            <Image
                                                id="qr-code"
                                                preview={false}
                                                height={'35px'}
                                                width={'35px'}
                                                src={qrCodeSrc}
                                                alt="qr code">
                                            </Image>
                                        </a>
                                    </Popover>
                                </div>

                                <Container style={{ margin: '40px auto' }}>
                                    <FieldArray name="slides">
                                        {({ push, remove, insert, move }) => {
                                            const handleAddSlideClick = () => {
                                                push(slideSchema);
                                                selectSlide(slides.length);
                                            };
                                            const handleDeleteSlideClick = (slideIndex) => {
                                                remove(slideIndex);
                                                selectSlide(slideIndex > 0 ? slideIndex - 1 : 0);
                                            }
                                            const handleDuplicateSlideClick = (slideIndex) => {
                                                insert(slideIndex, slides[slideIndex]);
                                                selectSlide(slideIndex + 1);
                                            }
                                            const handleReorderSlide = (sourceIdx, destinationIdx) => {
                                                move(sourceIdx, destinationIdx);
                                                selectSlide(destinationIdx);
                                            }
                                            return (
                                                <Column
                                                    style={{marginTop: 15}}
                                                    maxWidth="200px"
                                                    footerContent={
                                                        <Button
                                                            size="large"
                                                            style={{margin: '10px auto', width: '50px'}}
                                                            onClick={handleAddSlideClick}
                                                        >
                                                            <PlusOutlined />
                                                        </Button>
                                                    }
                                                >
                                                    <SlideList
                                                        experience={experience}
                                                        slides={slides}
                                                        onSlideClick={handleSlideClick}
                                                        onAddSlideClick={handleAddSlideClick}
                                                        onDeleteSlideClick={handleDeleteSlideClick}
                                                        onDuplicateSlideClick={handleDuplicateSlideClick}
                                                        onReorderSlide={handleReorderSlide}
                                                        currentSlideIndex={currentSlideIndex}
                                                    />
                                                </Column>
                                            )
                                        }}
                                    </FieldArray>
                                    <Column style={{marginTop: -20, height: '90vh', overflow:'scroll'}}>
                                        { slides.length ? (
                                            <div style={{transform: `scale(0.85)`, marginTop:-60 }} id="experience-preview ">
                                                <Preview  experience={experience} slide={slides[currentSlideIndex]} />
                                            </div>
                                            ) : null }
                                    </Column>
                                    {slides.length > 0 && (
                                        <>
                                            <FieldArray name={`slides[${currentSlideIndex}].droplets`}>
                                                {({ push, remove }) => {
                                                    const handleAddDroplet = (dropletType) => {
                                                        push(dropletSchema[dropletType]);
                                                        selectDroplet(slides[currentSlideIndex].droplets.length);
                                                    }
                                                    const handleDeleteDroplet = (dropletIndex) => {
                                                        remove(dropletIndex);
                                                    }
                                                    return (
                                                        <Column
                                                            style={{marginTop: 15}}
                                                            minWidth="600px"
                                                            maxWidth="600px"
                                                            flexGrow="1"
                                                            footerContent={
                                                                <AddDropletButton
                                                                    droplets={slides[currentSlideIndex].droplets}
                                                                    onDropletSelect={handleAddDroplet}
                                                                />
                                                            }
                                                            >
                                                                <DropletsPanel
                                                                    experience={experience}
                                                                    experienceId={experience._id}
                                                                    onSelectDroplet={selectDroplet}
                                                                    onDeleteDroplet={handleDeleteDroplet}
                                                                />
                                                        </Column>
                                                    )
                                                }}
                                            </FieldArray>
                                        </>
                                    )}
                                </Container>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        </Spin>
    );
};

const mapStateToProps = ({ editor, loading }) => ({ editor, loading });
const mapDispatchToProps = {
    getExperience,
    updateExperience,
    selectSlide,
    selectDroplet,
};

export default connect(mapStateToProps, mapDispatchToProps)(ExperienceEditor);
