import React, { Fragment } from "react";
import { Switch, Route } from 'react-router-dom';
import { Layout } from "antd";
import Experiences from './Experiences';
import ExperienceEditor from './ExperienceEditor';


const { Content } = Layout;

const Dashboard = ({ match }) => {
    return (
        <Fragment>
            <Content>
                <Switch>
                    <Route exact path={match.path} component={Experiences} />
                    <Route exact path={`${match.path}/experiences/:id/edit`} component={ExperienceEditor} />
                </Switch>
            </Content>
        </Fragment>
    );
};

export default Dashboard
