import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import { logIn } from "../../actions/auth";
import { Typography, Row, Col } from 'antd';
import LogInForm from './LogInForm';
import './Login.css';

const LogIn = ({ currentUser, logIn, loading, history }) => {
    const handleFormSubmit = values => {
        logIn(values).then(user => {
            if (user) history.push('/dashboard');
        });
    }

    return (
        currentUser ? (
            <Redirect to="/dashboard" />
        ) : (
            <Row justify="center">
                <Col flex="400px">
                    <div id="log-in">
                        <Typography.Title className="auth-title">Sign In</Typography.Title>
                        <LogInForm
                            onSubmit={handleFormSubmit}
                            loading={loading.LOG_IN}
                        />
                    </div>
                </Col>
            </Row>
        )
    )
}

const mapStateToProps = ({
    auth: { currentUser },
    loading,
}) => ({ currentUser, loading });
export default connect(mapStateToProps, { logIn })(LogIn);