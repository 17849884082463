export const slideSchema = {
    title: '',
    keyColor: '',
    droplets: [],
}

export const dropletSchema = {
    'PLAY_SAMPLE': {
        label: 'Audio Sample',
        type: 'PLAY_SAMPLE',
        data: {
            url: '',
        }
    },

    'SHOW_MAIN_IMAGE': {
        label: 'Main Image',
        type: 'SHOW_MAIN_IMAGE',
        data: {
            url: '',
        }
    },

    'SHOW_BACKGROUND_IMAGE': {
        label: 'Background Image',
        type: 'SHOW_BACKGROUND_IMAGE',
        data : {
            url: ''
        }
    },

    'SHOW_PARTNER_LOGO': {
        label: 'Partner Logo',
        type: 'SHOW_PARTNER_LOGO',
        data : {
            url: ''
        }
    },

    'SHOW_VIDEO': {
        label: 'Video',
        type: 'SHOW_VIDEO',
        data : {
            src: '',
            fitwidth: false,
        }
    },

    'SHOW_COLOR': {
        label: 'Color',
        type: 'SHOW_COLOR',
        data: {
            color: '',
        }
    },

    'SHOW_LINK': {
        label: "Link",
        type: 'SHOW_LINK',
        data: {
            prompt: 'Click Me',
            url: '',
            imgSrc: '',
        }
    },

    'SHOW_POLL': {
        label: 'Poll',
        type: 'SHOW_POLL',
        data: {
            prompt: '',
            primaryColor: '#FFFFFF',
            selectColor: '#FFFFFF',
            options: ['', '', ''],
        }
    },

    'SHOW_CAMERA': {
        label: 'Group Selfie',
        type: 'SHOW_CAMERA',
    },

    'SHOW_CUSTOM_HTML': {
        label: 'Custom HTML',
        type: 'SHOW_CUSTOM_HTML',
        data: {
            html: ''
        },
    },

    'SHOW_TEXT_INPUT': {
        label: 'Prompt',
        type: 'SHOW_TEXT_INPUT',
        data: {
            prompt: '',
        }
    },

    'ENTER_AR': {
        label: "Augmented Reality",
        type: 'ENTER_AR',
        data: {
            url: '',
        }
    },

    'SHOW_TEXT': {
        label: "Text",
        type: 'SHOW_TEXT',
        data: {
            text: '',
        }
    }
}