import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Empty } from 'antd';
import Slide from './Slide';

const SlideList = ({
    experience,
    slides,
    onSlideClick,
    onAddSlideClick,
    onDeleteSlideClick,
    onDuplicateSlideClick,
    onReorderSlide,
    currentSlideIndex,
}) => {

    const handleDragEnd = result => {
        const { source, destination } = result;

        if (!destination) return;

        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) return;

        onReorderSlide(source.index, destination.index);
    }

    return (
        <>
            {slides.length > 0 ? (
                <DragDropContext  onDragEnd={handleDragEnd}>
                    <Droppable droppableId={'slides-droppable'}>
                        {provided => (
                            <div {...provided.droppableProps} style={{width:'180px', padding: '2px 35px'}} ref={provided.innerRef}>
                                {slides.map((slide, idx) => (
                                    <Slide
                                        experience={experience}
                                        key={idx}
                                        slideIndex={idx}
                                        slide={slide}
                                        onSlideClick={onSlideClick.bind(this, idx)}
                                        onDeleteSlideClick={onDeleteSlideClick}
                                        onDuplicateSlideClick={onDuplicateSlideClick}
                                        selected={idx === currentSlideIndex}
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            ) : (
                <Empty
                    style={{cursor: 'pointer'}}
                    description="Click to add a slide"
                    onClick={onAddSlideClick}
                />
            )}
        </>
    )
}

export default SlideList;