import React from "react";
import { connect } from "react-redux";
import { roles, canAccessWithRole } from 'lib/auth';
import { logOut } from 'actions/auth';
import { Link, useLocation, withRouter } from "react-router-dom";
import { Layout, Menu } from "antd";
import logo from 'assets/sm-logo.png';
import InviteFriend from './InviteFriend';
import './NavBar.css';

const { Header } = Layout;

const NavBar = ({ currentUser, logOut, history }) => {
    const location = useLocation();

    const handleLogOutClick = () => {
        logOut();
        history.push('/login');
    }

    return (
        <Header className="header">
            <Link to="/">
                <img className="logo" src={logo} alt="splashmob logo" />
            </Link>
            <Menu
                className="splashmob-navigation"
                theme="dark"
                mode="horizontal"
                selectedKeys={[location.pathname]}
                style={{ lineHeight: "64px" }}
            >
                {currentUser ? [
                    // <InviteFriend key="invite-friend" />,
                    <Menu.Item key="/dashboard">
                        <Link to="/dashboard">Dashboard</Link>
                    </Menu.Item>,
                    canAccessWithRole(currentUser.role, roles.ADMIN) && (
                        <Menu.Item key="/admin">
                            <Link to="/admin">Admin</Link>
                        </Menu.Item>
                    ),
                    <Menu.Item key="/account">
                        <Link to="/account">Account</Link>
                    </Menu.Item>,
                    <Menu.Item key="logout" onClick={handleLogOutClick.bind(this)}>
                        Log Out
                    </Menu.Item>
                ] : [
                    <Menu.Item key="/login">
                        <Link to="/login">Sign In</Link>
                    </Menu.Item>
                ]}
            </Menu>
        </Header>
    );
};

const mapStateToProps = ({
    auth: { currentUser }
}) => ({ currentUser });
export default withRouter(connect(mapStateToProps, { logOut })(NavBar));
