import { types } from 'actions/auth';

const defaultState = { currentUser: null };
const authReducer = (state = defaultState, action) => {
    switch (action.type) {
        case types.LOG_IN_SUCCESS:
        case types.SIGN_UP_SUCCESS:
        case types.UPDATE_ACCOUNT_SUCCESS:
            return { currentUser: action.payload };
        case types.LOG_OUT:
            return defaultState;
        default: return state;
    }
}

export default authReducer;