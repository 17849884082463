// these Maps are used for consistency in messaging when describing aspects of splashmob, such as the names of droplets and their descriptions.

export const dropletLabelsByType = new Map();

dropletLabelsByType
    .set('SHOW_TEXT', 'Text')
    .set('SHOW_LINK', 'Link')
    .set('SHOW_MAIN_IMAGE', 'Image')
    .set('SHOW_VIDEO', 'Video')
    .set('PLAY_SAMPLE', 'Audio')
    .set('SHOW_POLL', 'Poll')
    .set('SHOW_TEXT_INPUT', 'Form')
    .set('SHOW_CUSTOM_HTML', 'HTML');
    // .set('SHOW_BACKGROUND_IMAGE', 'Background Image')
    // .set('SHOW_COLOR', 'Color')
    // .set('SHOW_PARTNER_LOGO', 'Partner Logo')
    // .set('SHOW_CAMERA', 'Group Selfie')
    // .set('ENTER_AR', 'Augmented Reality')

export const dropletDescriptionsByType = new Map();

dropletDescriptionsByType
    .set('SHOW_TEXT', 'Add your own text in the input field to any of your mobile views. This layer will appear on top of all other content on your slide.')
    .set('SHOW_LINK', 'Add links to anywhere on the web using text or an icon to represent that link. This layer will appear above all other droplets added to that slide.')
    .set('SHOW_MAIN_IMAGE', 'Use any image file to add a photo to any slide. This layer will be positioned in the middle of the viewer’s mobile view.')
    .set('SHOW_VIDEO', 'Use any .mp4 .mov file to add videos to your slide. This layer can be toggled between a full-screen view and an uncropped middle positioning using the “Fit Width” selector. Also, loop videos by selecting “Loop” in the panel on the right-hand side of your screen.')
    .set('PLAY_SAMPLE', 'Use an .mp3 or .m4a audio file to add sound to your slide. The audio you add will automatically play when you switch to this slide.')
    .set('SHOW_POLL', 'Create a prompt and use three responses to gather input from your audience. Your viewer’s input will be displayed directly on your controller.')
    .set('SHOW_TEXT_INPUT', 'Create a prompt and gather text inputs from your audience. Your viewer’s input will be displayed directly on your controller.')
    .set('SHOW_CUSTOM_HTML', 'Add HTML code to any slide allowing enhanced styling capabilities and simple embed codes.');
    // .set('SHOW_BACKGROUND_IMAGE', 'Use any image file to create a background image for your slide. This file can be cropped and fit to the mobile view directly on the dashboard.')
    // .set('SHOW_COLOR', 'Use basic color names or HEX codes to add a background color to any slide.')