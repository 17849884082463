import React from 'react';
import { Form, Input, Select } from 'antd';
import { FastField } from 'formik';
import FileUploader from 'components/Dashboard/FileUploader';

const { Option } = Select;

const Link = ({ dataPath, experienceId, touched, errors }) => (
    <>
        <Form.Item
            label="Prompt"
            help={touched?.prompt && errors?.prompt}
            validateStatus={touched?.prompt && errors?.prompt ? 'error' : 'success'}
        >
            <FastField name={`${dataPath}.prompt`} as={Input} size="large" placeholder="Prompt" />
        </Form.Item>
        <Form.Item
            label="Link URL"
            help={touched?.url && errors?.url}
            hasFeedback={touched?.url}
            validateStatus={touched?.url && errors?.url ? 'error' : 'success'}
        >
            <FastField name={`${dataPath}.url`} as={Input} size="large" placeholder="URL" />
        </Form.Item>
        <Form.Item label="Custom Image (optional)">
            <FastField name={`${dataPath}.imgSrc`}>
                {({ field: { value }, form: { setFieldValue }}) => (
                    <FileUploader
                        experienceId={experienceId}
                        onUploadSuccess={url => setFieldValue(`${dataPath}.imgSrc`, url)}
                        onRemoveFile={() => setFieldValue(`${dataPath}.imgSrc`, '')}
                        value={value}
                        accept="image/*"
                    />
                )}
            </FastField>
        </Form.Item>
        <Form.Item label="Vertical Alignment">
            <FastField name={`${dataPath}.style.align-self`}>
                {({ field: { value }, form: { setFieldValue } }) => (
                    <Select
                        placeholder="Select an alignment"
                        style={{ width: 200 }}
                        value={value}
                        size="large"
                        onSelect={(optionValue) => setFieldValue(`${dataPath}.style.align-self`, optionValue)}
                    >
                        {[
                            { value: 'flex-start', label: "Top" },
                            { value: 'center', label: "Middle" },
                            { value: 'flex-end', label: "Bottom" },
                        ].map(({ value, label }, idx) => <Option key={idx} value={value}>{label}</Option>)}
                    </Select>
                )}
            </FastField>
        </Form.Item>
    </>
);

export default Link;